import { SbBlokData, storyblokEditable } from 'gatsby-source-storyblok';
import React from 'react';
import EmbeddedContent from '../EmbeddedContent';

type CollectionMetafield = {
  description?: string;
  key: string;
  namespace: string;
  type: string;
  value: string;
};

type Props = {
  blok: SbBlokData;
  collection?: {
    description: string;
    descriptionHtml: string;
    handle: string;
    metafields: CollectionMetafield[];
  };
};

export default function DynamicCollectionSpotifyPlaylist({
  blok,
  ...rest
}: Props) {
  const collection = rest?.collection;

  const playlistCode = collection?.metafields?.find(
    (metafield) => metafield.key === 'spotify_playlist_embed_code'
  )?.value;

  if (!playlistCode) {
    return null;
  }

  return (
    <div {...storyblokEditable(blok)} style={{ width: '100%' }}>
      <EmbeddedContent embedCode={playlistCode} />
    </div>
  );
}
